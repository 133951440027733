import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import departmentIcon from "../../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
import successIcon from "../../../assets/images/Icons/SVG/success.svg";
import greentickIcon from "../../../assets/images/Icons/SVG/Icon-greentick.svg";
// import greentickIcon from "../../../assets/images/Icons/SVG/green-tick.svg";
import { useFormik } from "formik";
import API from "../../../Api/Api";
import RouteName from "../../../Routes/Routename";
import PaymentSuccessModal from "./PaymentSuccessModal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { paymentData } from "../../../Redux/Slice";
import axios from "axios";
// import baseApi from "../../Api/config";
import baseApi from "../../../Api/config";

const ShareAccess = (props) => {
   // const [departmentList, setDepartmentList] = useState(null);
   const deptToken = localStorage.getItem("DepartmentToken");
   const [loading, setLoading] = useState(false);
   const [loading1, setLoading1] = useState(false);

   const [amount, setAmount] = useState();
   const [orderData, setOrderData] = useState();
   const [departmentIdArray, setDepartmentIdArray] = useState(null);
   const [transactionResponse, setTrasactionResponse] = useState();
   const [currentDate, setCurrentDate] = useState(new Date());
   const [selectedPlan, setSelectedPlan] = useState("");
   const [isHoveredQuarterly, setIsHoveredQuarterly] = useState(false);
   const [isHoveredYearly, setIsHoveredYearly] = useState(false);
   const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
   const [afterPayment, setAfterPayment] = useState(false);
   const [deleteId, setDeleteId] = useState();
   const [paymentSuccessfulRes, setPaymentSuccessfulRes] = useState();
   const [isLastDepartmentDeleted, setIsLastDepartmentDeleted] = useState(false);
   const [totalPaidAmount, setTotalPaidAmount] = useState(0);
   const [departmentListLocallyPublish, setDepartmentListLocallyPublish] = useState([]);
   const [subscriptionDetails, setSubscriptionDetails] = useState(null);
   const [selectedPlanAmountDetails, setselectedPlanAmountDetails] = useState("");
   const [validCoupon, setValidCoupon] = useState(false);

   console.log("!!!!!!!!!", props);

   const navigate = useNavigate();
   const dispatch = useDispatch();

   useEffect(() => {
      // Retrieve the department list from local storage
      const storedDepartmentList = localStorage.getItem("departmentListLocallyPublish");
      if (storedDepartmentList) {
         const parsedList = JSON.parse(storedDepartmentList);
         setDepartmentListLocallyPublish(parsedList);
         console.log("parsed list ______", parsedList);
      }
      // // Retrieve paymentSuccessModal state from localStorage on component mount
      // const paymentSuccessModalState = localStorage.getItem(
      //   "paymentSuccessModal"
      // );
      // if (paymentSuccessModalState === "true") {
      //   setPaymentSuccessModal(true);
      // } else {
      //   setPaymentSuccessModal(false);
      // }
   }, []);
   const validate = (values) => {
      console.log(values, "value");
      const regex = /^[^\s].*$/;
      const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

      const errors = {};
      if (!values.about_us) {
         errors.about_us = "Please enter about us";
      } else if (!regex.test(values.about_us)) {
         errors.about_us = "Cannot start with a space";
      }
      if (!values.mission) {
         errors.mission = "Please enter mission";
      } else if (!paragraphReg.test(values.mission)) {
         errors.mission = "Cannot start with a space";
      }
      if (!values.vision) {
         errors.vision = "Please enter vision";
      } else if (!paragraphReg.test(values.vision)) {
         errors.vision = "Cannot start with a space";
      }
      if (!values.firstKeyFactor) {
         errors.firstKeyFactor = "Please enter firstKeyFactor";
      } else if (!regex.test(values.firstKeyFactor)) {
         errors.firstKeyFactor = "Cannot start with a space";
      }

      if (!values.secondKeyFactor) {
         errors.secondKeyFactor = "Please enter secondKeyFactor";
      } else if (!regex.test(values.secondKeyFactor)) {
         errors.secondKeyFactor = "Cannot start with a space";
      }

      if (!values.thirdKeyFactor) {
         errors.thirdKeyFactor = "Please enter thirdKeyFactor";
      } else if (!regex.test(values.thirdKeyFactor)) {
         errors.thirdKeyFactor = "Cannot start with a space";
      }
      if (!values.purpose) {
         errors.purpose = "Please enter purpose";
      } else if (!paragraphReg.test(values.purpose)) {
         errors.purpose = "Cannot start with a space";
      }
      if (!values.solution) {
         errors.solution = "Please enter solution";
      } else if (!paragraphReg.test(values.solution)) {
         errors.solution = "Cannot start with a space";
      }
      console.log("Erroes", errors);
      return errors;
   };
   const formik = useFormik({
      initialValues: {
         about_us: "",
         mission: "",
         vision: "",
         firstKeyFactor: "",
         secondKeyFactor: "",
         thirdKeyFactor: "",
         purpose: "",
         solution: "",
      },
      onSubmit: (values) => {
         console.log(values, "hello values");
         shareInviteSubmitApi();
      },
      validate,
   });
   // const getDeaprtmentInviteList = () => {
   //   API.Auth.GetAllOrgDepartmentsAPI()
   //     .then((response) => {
   //       setDepartmentList(response?.data?.data);
   //     })
   //     .catch((err) => console.log(err));
   // };
   // useEffect(() => {
   //   getDeaprtmentInviteList();
   // }, []);
   // const getDepartmentList = () => {
   //   API.Auth.GetAllOrgDepartmentsAPI()
   //     .then((response) => {
   //       setDepartmentList(response?.data?.data);
   //     })
   //     .catch((err) => console.log(err));
   // };
   // useEffect(() => {
   //   getDepartmentList();
   // }, []);
   const deleteDepartmentId = (id) => {
      setDeleteId(id);
   };

   const selectPlanButtonStyleYearly = {
      backgroundColor: isHoveredYearly || selectedPlan === "annual" ? "#6993ff" : "",
      color: isHoveredYearly || selectedPlan === "annual" ? "#fff" : "#6993ff",
      transition: "color 0.2s ease",
   };
   const selectPlanButtonStyleQuarterly = {
      backgroundColor: isHoveredQuarterly || selectedPlan === "quaterly" ? "#6993ff" : "",
      color: isHoveredQuarterly || selectedPlan === "quaterly" ? "#fff" : "#6993ff",
      transition: "color 0.2s ease",
   };

   // const departmentListCount = props?.departmentListLocallyPublish?.length;
   const departmentListCount = departmentListLocallyPublish.length;
   // const costPerDepartment = 100;
   // Determine the cost per department based on the selected plan
   // let costPerDepartment;
   // let quaterlyPlanType = "quaterly";
   // let annualPlanType = "annual";

   // if (quaterlyPlanType === "quaterly") {
   //   console.log("Select Plan --", selectedPlan);
   //   costPerDepartment = 250;
   //   console.log("Cost of Dept --", costPerDepartment);
   // } else if (annualPlanType === "annual") {
   //   console.log("Select Plan --", selectedPlan);
   //   costPerDepartment = 225;
   //   console.log("Cost of Dept --", costPerDepartment);
   // }
   const costPerDepartmentQuaterly = 250;
   const costPerDepartmentYearly = 225;

   const discountPercentage = 5;
   const quaterlyCostWithoutGst = costPerDepartmentQuaterly * departmentListCount * 3;
   console.log("Quaterly cost of Dept witout gst --", quaterlyCostWithoutGst);
   const quaterlyCostGstAmount = Math.ceil((quaterlyCostWithoutGst * 18) / 100);
   const quarterlyCost = quaterlyCostWithoutGst + quaterlyCostGstAmount;
   console.log("Quaterly Cost of Department gst including ", quarterlyCost);
   const yearlyCost = costPerDepartmentYearly * departmentListCount * 12;
   const yearlyDiscount = yearlyCost * (discountPercentage / 100);
   const yearlyCostwithGstWithoutDiscount = yearlyCost + Math.ceil((yearlyCost * 18) / 100);
   const discountedYearlyCostWithoutGst = yearlyCost - yearlyDiscount;
   console.log("Discounted Yearly Cost of Department without gst", discountedYearlyCostWithoutGst);
   const discountedYearlyGstAmount = Math.ceil((discountedYearlyCostWithoutGst * 18) / 100);
   const discountedYearlyCost = discountedYearlyCostWithoutGst + discountedYearlyGstAmount;
   console.log("Discounted Yearly Cost of Department", discountedYearlyCost);
   console.log("total_paid_amount with gst_amount", totalPaidAmount);
   const gst_amount = Math.ceil((totalPaidAmount * 18) / (100 + 18));
   const total_paid_amount_without_gst = totalPaidAmount - gst_amount;
   console.log("gst_amount", gst_amount);
   console.log("total_paid_amount_without_gst", total_paid_amount_without_gst);

   const deleteDepartment = () => {
      // API.Auth.DeactivateDepartment({
      //   data: {
      //     _id: deleteId,
      //   },
      // })
      //   .then((response) => {
      //     console.log(response, "-------");
      //     // document.querySelector(".btn-close").click();
      //     // document.querySelector("#staticBackdrop1").style.display = "none";
      //     document.querySelector("#DeleteDepartemnt").click();

      //     getDeaprtmentInviteList();
      //   })
      //   .catch((err) => console.log(err));
      // const filteredList = props?.departmentListLocallyPublish?.filter(
      //   (item) => item?.dept_name !== deleteId
      // );
      // props?.setDepartmentListLocallyPublish(filteredList);
      const filteredList = departmentListLocallyPublish.filter((item) => item?.dept_name !== deleteId);
      setDepartmentListLocallyPublish(filteredList);
      document.querySelector(".btn-close").click();
      document.querySelector("#DeleteDepartemnt").click();
      // if (props?.departmentListLocallyPublish?.length === 1) {
      //   setIsLastDepartmentDeleted(true);
      // }
      console.log("Filtered List after deletion:", filteredList);

      if (filteredList.length === 0) {
         setIsLastDepartmentDeleted(true);
         console.log("Last department deleted!");
      }
   };


   const couponForm = useFormik({
      initialValues: {
         coupon: "",
      },
      onSubmit: async (values, { setSubmitting, setErrors }) => {
         const { coupon } = values;
         setSubmitting(true);
         try {
            const res = await validateCoupon(coupon);
            if (res.data.statusCode === 200) {
               setValidCoupon(true);
               setLoading(false);
               console.log("🐱‍💻🐱‍💻", res);
               API.Auth.ShareInviteAPI({
                  data: {
                     flag: 5,
                  },
               }).catch((err) => {
                  console.log("🐱‍💻🐱‍💻", err);
               });
               setPaymentSuccessModal(true);
               setAfterPayment(true);
               setPaymentSuccessfulRes(res?.data);
               localStorage.setItem(
                  "paymentSuccessfulRes",
                  JSON.stringify(res?.data)
               );
               dispatch(paymentData(res?.data));
               setTotalPaidAmount(0);
               setTimeout(() => {
                  document.querySelector("#shareInvite").click();
               }, 1000);
            }
            else {
               toast.error("Invalid Coupon");
            }
         } catch (err) {
            toast.error("Invalid Coupon");
            console.error(err);
         } finally {
            setSubmitting(false);
         }
      },
      validate: (values) => {
         const errors = {};
         if (!values.coupon) {
            errors.coupon = "Please enter coupon";
         }
         return errors;
      },
   });
   useEffect(() => {
      if (isLastDepartmentDeleted) {
         // If the last department was deleted, navigate to the define department page
         props.handleBack();
         navigate(`../${RouteName.Auth.Registration}?id=3`, { replace: true });
      }
   }, [isLastDepartmentDeleted, navigate]);

   const handleEdit = (id) => {
      props.handleBack();
      navigate(`../${RouteName.Auth.Registration}?id=3&editId=${id}`, {
         replace: true,
      });
   };

   const shareInviteOnEmail = () => {
      setLoading(true);
      localStorage.removeItem("departmentListLocally");
      API.Auth.ShareInviteAPI({
         data: {
            flag: 5,
         },
      })
         .then((response) => {
            setLoading(false);
            if (response.data.org_user.org_Info_filled === false) {
               document.querySelector("#shareInvite").click();
            } else {
               navigate("/dashboard");
            }
         })
         .catch((err) => {
            console.log(err);
            setLoading(false);
         });
   };

   const shareInviteSubmitApi = () => {
      API.Auth.ShareInviteSubmitAPI({
         data: {
            about_us: formik.values.about_us,
            mission: formik.values.mission,
            vision: formik.values.vision,
            profileImageUrl: null,
            companyLogoUrl: null,
            differentiator: {
               firstKeyFactor: formik.values.firstKeyFactor,
               secondKeyFactor: formik.values.secondKeyFactor,
               thirdKeyFactor: formik.values.thirdKeyFactor,
            },
            purpose: formik.values.purpose,
            solution: formik.values.solution,
            org_Info_filled: true,
         },
      })
         .then((response) => {
            if (response.data.statusCode === 200) {
               console.log(response, "responseresponse");
               const close = document.querySelector(".btn-close");
               if (close) {
                  close.click();
               }
               navigate("/dashboard");
            }
         })
         .catch((err) => console.log(err));
   };

   const calculateEndDate = () => {
      const endDate = new Date(currentDate); // Create a copy of the current date
      if (selectedPlan === "quaterly") {
         // endDate.setMonth(endDate.getMonth() + 3); // Add 3 months
         endDate.setDate(endDate.getDate() + 90);
      } else if (selectedPlan === "annual") {
         // endDate.setFullYear(endDate.getFullYear() + 1); // Add 1 year
         endDate.setDate(endDate.getDate() + 365);
      }

      const year = endDate.getFullYear();
      const month = ("0" + (endDate.getMonth() + 1)).slice(-2);
      const day = ("0" + endDate.getDate()).slice(-2);

      const formattedEndDate = `${year}/${month}/${day}`;
      return formattedEndDate;
   };

   const formatDate = (date) => {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${year}/${month}/${day}`;
   };


   const validateCoupon = async (coupon) => {
      return API.Auth.ValidateCoupon({
         data: {
            coupon,
            departmentCount: departmentListCount,
            department_array: departmentListLocallyPublish
         }
      })
   }

   // const showRazorpay = async () => {
   //   try {
   //     // const res = await loadScript(
   //     //   "https://checkout.razorpay.com/v1/checkout.js"
   //     // );
   //     // if (!res) {
   //     //   throw new Error("Razorpay SDK failed to load. Are you online?");
   //     // }

   //     if (amount) {
   //       const orderResponse = await API.Auth.createPaymentOrder({
   //         data: {
   //           amount: Number(amount),
   //         },
   //       });

   //       if (orderResponse.data.statusCode === 200) {
   //         const orderData = orderResponse.data.data;

   //         const options = {
   //           key: "rzp_test_BF1ASnSComgPmw",
   //           amount: orderData?.amount?.toString(),
   //           currency: "INR",
   //           order_id: orderData?.razorpay_order_id,
   //           name: "EasyOKR",
   //           description: "Thank you for the payment.",
   //           image:
   //             "https://serverqodequay.s3.amazonaws.com/tajurba/profile/1705904286856_img.jpeg",
   //           handler: function (response) {
   //             console.log("Transaction successful:", response);
   //             setTrasactionResponse(response)
   //             // You can handle the successful transaction here
   //             // Example: Send payment details to your backend
   //             API.Auth.createMultipleDepartment({
   //               data: {
   //                 department_array: props?.departmentListLocallyPublish,
   //                 flag: 4
   //               },
   //             })
   //               .then(async (response) => {
   //                 if (response.data.statusCode === 200) {
   //                   // console.log("🐱‍👤🐱‍👤", response)
   //                   console.log("🐱‍👤🐱‍👤", transactionResponse)

   //                   // setDepartmentIdArray(response?.data?.data)
   //                   // console.log("✔✔", departmentIdArray)
   //                   await API.Auth.createSubscription({
   //                     data: {
   //                       amount: amount,
   //                       razorpay_payment_id: transactionResponse?.razorpay_payment_id,
   //                       razorpay_order_id: transactionResponse?.razorpay_order_id,
   //                       expiry_date: calculateEndDate(),
   //                       department_array: response?.data?.data?.map((item) => item?._id),
   //                       organization_id: response?.data?.data[0]?.organization_id,
   //                       subscription_data: {
   //                         plan_type: selectedPlan,
   //                         organization_id: response?.data?.data[0]?.organization_id,
   //                         payment_detail_id: "",
   //                         department_array: [],
   //                         department_count: response?.data?.data[0]?.length,
   //                         payment_status: "success",
   //                         start_date: formatDate(currentDate),
   //                         end_date: calculateEndDate(),
   //                         discount_amount: amount,
   //                         discount_percentage: discountPercentage,
   //                       }
   //                     },
   //                   })
   //                     .then((response) => {
   //                       console.log("🐱‍💻🐱‍💻", response)
   //                       setPaymentSuccessModal(true)
   //                     })
   //                     .catch((err) => {
   //                       console.log(err);
   //                     });
   //                 }
   //               })
   //               .catch((err) => {
   //                 console.log(err);
   //               });
   //           },
   //           prefill: {
   //             name: "Rajat",
   //             email: "rajat@rajat.com",
   //             phone: "9899999999",
   //           },
   //         };

   //         const paymentObject = new window.Razorpay(options);
   //         paymentObject.open();
   //       }
   //     }
   //   } catch (error) {
   //     console.error("Error:", error);
   //     alert(error.message);
   //   }
   // };

   const showRazorpay = async () => {
      var amountForPayment =
         selectedPlanAmountDetails?.planType === "quaterly"
            ? selectedPlanAmountDetails?.quaterlyCostWithGst
            : selectedPlanAmountDetails?.discountedYearlyCostWithGst;

      // console.log("amountForPayment", amountForPayment);
      try {
         const orderResponse = await API.Auth.createPaymentOrder({
            data: {
               amount: amountForPayment,
            },
         });

         if (orderResponse.data.statusCode === 200) {
            const orderData = orderResponse.data.data;

            const options = {
               key: "rzp_test_BF1ASnSComgPmw",
               amount: orderData?.amount?.toString(),
               currency: "INR",
               order_id: orderData?.razorpay_order_id,
               name: "EasyOKR",
               description: "Thank you for the payment.",
               image: "https://serverqodequay.s3.amazonaws.com/tajurba/profile/1705904286856_img.jpeg",
               handler: function (response) {
                  console.log("Transaction successful:", response);
                  // setTrasactionResponse(response);
                  // You can handle the successful transaction here
                  // Example: Send payment details to your backend
                  API.Auth.createMultipleDepartment({
                     data: {
                        department_array: departmentListLocallyPublish,
                        flag: 4,
                     },
                  })
                     .then(async (createDepartmentResponse) => {
                        if (createDepartmentResponse.data.statusCode === 200) {
                           await API.Auth.createSubscription({
                              data: {
                                 // amount: amount,
                                 amount: amountForPayment,
                                 razorpay_payment_id: response?.razorpay_payment_id,
                                 razorpay_order_id: response?.razorpay_order_id,
                                 expiry_date: calculateEndDate(),
                                 department_array: createDepartmentResponse?.data?.data?.map((item) => item?._id),
                                 organization_id: createDepartmentResponse?.data?.data[0]?.organization_id,
                                 subscription_data: {
                                    plan_type: selectedPlan,
                                    organization_id: createDepartmentResponse?.data?.data[0]?.organization_id,
                                    payment_detail_id: "",
                                    department_array: [],
                                    department_count: createDepartmentResponse?.data?.data?.length,
                                    payment_status: "success",
                                    subscription_status: "active",
                                    start_date: formatDate(currentDate),
                                    end_date: calculateEndDate(),
                                    discount_amount: amount,
                                    discount_percentage: selectedPlanAmountDetails?.planType === "quaterly" ? 0 : 10,
                                 },
                              },
                           })
                              .then((createSubscriptionResponse) => {
                                 if (createSubscriptionResponse?.data?.statusCode === 200) {
                                    console.log("🐱‍💻🐱‍💻", createSubscriptionResponse);
                                    setPaymentSuccessModal(true);
                                    setAfterPayment(true);
                                    setPaymentSuccessfulRes(createSubscriptionResponse?.data);
                                    // Store paymentSuccessfulRes in localStorage
                                    localStorage.setItem(
                                       "paymentSuccessfulRes",
                                       JSON.stringify(createSubscriptionResponse?.data)
                                    );
                                    dispatch(paymentData(createSubscriptionResponse?.data));
                                    console.log(
                                       "paid ampunt is here ^^^^^^^^^^^^^^^^",
                                       createSubscriptionResponse.data.data.paid_amount
                                    );
                                    setTotalPaidAmount(createSubscriptionResponse.data.data.paid_amount);
                                 }
                              })
                              .catch((err) => {
                                 toast.error("Subscription Failed");
                                 console.error(err);
                                 localStorage.removeItem("departmentListLocally");
                              });
                        }
                     })
                     .catch((err) => {
                        console.error(err);
                     });
               },
               prefill: {
                  name: "Rajat",
                  email: "rajat@rajat.com",
                  phone: "9899999999",
               },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
         }
      } catch (error) {
         console.error("Error:", error);
         alert(error.message);
      }
   };

   const generatePaymentInvoice = (event) => {
      event.preventDefault();
      let costPerDepartment;
      setLoading1(true);
      console.log("paymentSuccessfulRes", paymentSuccessfulRes);

      if (selectedPlan === "quaterly") {
         costPerDepartment = 499;
      } else if (selectedPlan === "annual") {
         costPerDepartment = 449;
      }
      // const payload = {
      //   total_paid_amount_without_gst: total_paid_amount_without_gst,
      //   gst_amount: gst_amount,
      //   total_paid_amount_with_gst: totalPaidAmount,
      //   costPerDepartment: costPerDepartment,
      //   departments: departmentListLocallyPublish,
      // };

      const payload = {
         total_paid_amount_without_gst: paymentSuccessfulRes?.subscription_data?.discount_amount,
         gst_amount: paymentSuccessfulRes?.data?.paid_amount - paymentSuccessfulRes?.subscription_data?.discount_amount,
         total_paid_amount_with_gst: totalPaidAmount,
         costPerDepartment: costPerDepartment,
         departments: departmentListLocallyPublish,
      };

      API.Auth.getInvoicePDF(payload)
         .then((response) => {
            console.log(response);
            if (response.data.statusCode) {
               console.log("INVOICE API CALL SUCCESS");

               const filePath = response.data.filePath;

               if (filePath) {
                  const baseApiUrl = baseApi.baseurl;
                  const fileUrl = `${baseApiUrl}${filePath}`;
                  const fileName = filePath.split("/").pop();

                  const downloadLink = document.createElement("a");
                  downloadLink.href = fileUrl;
                  downloadLink.style.display = "none";
                  downloadLink.download = fileName;
                  downloadLink.target = "_blank";

                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);

                  toast.success("Invoice downloaded successfully!");
               } else {
                  console.error("Missing filePath in response data");
                  toast.error("Failed to generate the invoice. Missing file path.");
               }
               setLoading1(false);
            } else {
               setLoading1(false);
               toast.error("Failed to generate the invoice.");
            }
         })
         .catch((err) => {
            console.error(err);
            toast.error("Failed to generate the invoice.");
         });
   };

   const get_Subscription_Details = () => {
      console.log("DEPARTMENT LIST COUNT API", departmentListCount);
      if (departmentListCount) {
         API.Auth.getSubscriptionDetails(departmentListCount)
            .then((response) => {
               if (response.data.statusCode === 200) {
                  console.log("*************#####", response.data.data);
                  setSubscriptionDetails(response.data.data);
               }
            })
            .catch((err) => {
               if (err.response.statusCode === 403) {
                  navigate("/");
               }
               console.log(err);
            });
      }
   };

   useEffect(() => {
      get_Subscription_Details();
   }, [departmentListCount]);

   useEffect(() => {
      if (afterPayment) {
         setTimeout(() => {
            setAfterPayment(false);
         }, 3000);
      }
   }, [afterPayment]);

   // console.log("&^&^&^&^&^", totalPaidAmount);
   // console.log("***********", transactionResponse);
   // console.log("payment modal value", paymentSuccessModal);
   // console.log("coost per dept", subscriptionDetails?.quaterly_plan?.planType);


   useEffect(() => {
      console.log("selectedPlanAmountDetails", selectedPlanAmountDetails);
   }, [selectedPlanAmountDetails]);

   return (
      <div className="mt-4">
         {!paymentSuccessModal ? (
            <div className="row justify-content-center mt-4">
               {/* need tobe changed */}

               <div className="col-xxl-12 col-12">
                  <h2 className="textBlue fw-normal h4">
                     <span className="textDarkGrey h4 fw-normal"></span> Proceed With Payment
                  </h2>
                  <p className="textGrey">Kindly make the payment before starting your EasyOKR journey.</p>
                  <div className="row m-0 gap-5">
                     <div className="col-xl-6 mb-1 flex-column d-flex  emailoOuterContainer ps-0 pe-0 scroll-max-height custom-scrollnar">
                        {departmentListLocallyPublish &&
                           departmentListLocallyPublish?.map((item, index) => {
                              return (
                                 <>
                                    <div className="row m-0 row-cols-2 p-2" key={index}>
                                       <div className="col-10 ">
                                          <div className="setObjectiveListBox row p-3 d-flex">
                                             <div className="maxWidth30 ps-0" style={{ maxWidth: "30px" }}>
                                                <img src={departmentIcon} className="" alt="department icon" />
                                             </div>
                                             <div className="setObjectiveListBox pe-0" style={{ maxWidth: "calc(100% - 30px)" }}>
                                                <p className="mb-1 fw-medium" style={{ overflowWrap: "break-word" }}>
                                                   {item?.dept_name}
                                                </p>
                                                <p
                                                   className="textBlueLight1 mb-0 fw-medium"
                                                   style={{ overflowWrap: "break-word" }}
                                                >
                                                   {item?.email}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-2 d-flex ps-2">
                                          <div className="d-flex align-middle flex-column flex-md-row flex-lg-row flex-xl-row flex-xxl-row">
                                             <img
                                                src={editIcon}
                                                onClick={() => handleEdit(item.dept_name)}
                                                className="cursor-pointer icon-size"
                                                alt="edit icon"
                                             />
                                             <button
                                                type="button"
                                                className="btn ps-0 ms-1 pe-0"
                                                data-bs-toggle="modal"
                                                id="DeleteDepartemnt"
                                                data-bs-target="#staticBackdrop1"
                                                onClick={() => deleteDepartmentId(item.dept_name)}
                                             >
                                                <img src={deleteIcon} alt="delete icon" />
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              );
                           })}
                     </div>
                     <div className="col mx-n3 share-invite-right p-0">
                        <p className="textGrey fw-regular mb-2">Total Departments Added</p>
                        <h3 className="textBlueLight1 fw-normal h3 mb-3">{departmentListLocallyPublish?.length}</h3>
                        <div className="d-flex align-items-center gap-3">
                           <div className="flex-grow-1">
                              <input
                                 className="form-control"
                                 style={{ height: "2.5rem" }}
                                 type="text"
                                 placeholder="Enter Coupon Code"
                                 name="coupon"
                                 value={couponForm.values.coupon}
                                 onChange={couponForm.handleChange}
                              />
                              {couponForm.errors.coupon && couponForm.touched.coupon ? (
                                 <div className="text-danger mt-1">{couponForm.errors.coupon}</div>
                              ) : null}
                           </div>
                           <button
                              className="btn btn-primary"
                              style={{ height: "2.5rem" }}
                              onClick={couponForm.handleSubmit}
                           >
                              Validate Coupon
                           </button>
                           
                        </div>
                        
                        <p className="textGrey fw-regular mb-0 mt-3">
                           Total Amount Payable
                           {/* <img src={greentickIcon} width="17" className="ms-1" /> */}
                        </p>
                        <small class="lightGrey mb-2 d-block">Choose your preferred package and proceed with the payment.</small>
                        <b className="textGrey fw-regular mb-0">Inclusive GST (18%)</b>

                        {/* <div class="list-group" id="list-tab" role="tablist">
              <div class="price-tabs active mb-3">
                   <h4 class="h2 textBlueLight1 fw-regular">₹800 <span className="h4">monthly</span></h4>
                   <small class="textDarkGrey mb-0">The cost for an individual department is ₹100 per month</small>
              </div>
              <div class="price-tabs mb-3">
                   <h4 class="h2 textBlueLight1 fw-regular">₹9000 <span className="h4">yearly</span></h4>
                   <small class="textDarkGrey mb-0">The cost for an individual department is ₹1200 for a year</small>
              </div>
  </div> */}
                        <div className={validCoupon ? "disabled" : ""}>
                           <div className={`list-group`} id="list-tab" role="tablist">
                              <div
                                 class={`price-tabs mb-3 textBlueLight1`}
                                 onClick={() => {
                                    console.log("subscriptionDetails", subscriptionDetails);
                                    setSelectedPlan(subscriptionDetails?.quaterly_plan?.planType);
                                    // setAmount(quarterlyCost);
                                    setAmount(subscriptionDetails?.quaterly_plan?.totalQuaterlyCost);
                                    setselectedPlanAmountDetails(subscriptionDetails?.quaterly_plan);
                                 }}
                                 style={selectPlanButtonStyleQuarterly}
                                 onMouseEnter={() => setIsHoveredQuarterly(true)}
                                 onMouseLeave={() => setIsHoveredQuarterly(false)}
                              >
                                 <div className="row m-0">
                                    <div className="col-md-9 p-0">
                                       <h4 className="h5 fw-normal">
                                          {" "}
                                          Quarterly Plan <small className="small p fs-6">(90 days)</small>
                                       </h4>
                                       <small className="mb-0">
                                          The cost for an individual department is{" "}
                                          {/* {costPerDepartment ? costPerDepartment : 100} per
                          month. */}
                                          {subscriptionDetails?.costPerDepartment} per month.
                                       </small>
                                    </div>
                                    <div className="col-md-3 pe-0">
                                       <h3 className="h4 text-right">
                                          ₹
                                          {subscriptionDetails?.quaterly_plan?.totalQuaterlyCost
                                             ? subscriptionDetails?.quaterly_plan?.totalQuaterlyCost
                                             : 0}
                                       </h3>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className={`list-group mt-1`} id="list-tab" role="tablist">
                              <div
                                 class={`price-tabs mb-3 textBlueLight1`}
                                 onClick={() => {
                                    setSelectedPlan(subscriptionDetails?.annual_plan?.planType);
                                    // setAmount(discountedYearlyCost);
                                    setAmount(subscriptionDetails?.annual_plan?.discountedYearlyCost);
                                    setselectedPlanAmountDetails(subscriptionDetails?.annual_plan);
                                 }}
                                 style={selectPlanButtonStyleYearly}
                                 onMouseEnter={() => setIsHoveredYearly(true)}
                                 onMouseLeave={() => setIsHoveredYearly(false)}
                              >
                                 <div className="row m-0">
                                    <div className="col-md-9 p-0">
                                       <h4 className="h5 fw-normal">
                                          {" "}
                                          Annual Plan{" "}
                                          <small className="small p fs-6">
                                             (365 days) with{" "}
                                             {subscriptionDetails?.annual_plan?.discountPercentage
                                                ? subscriptionDetails?.annual_plan?.discountPercentage
                                                : 5}
                                             % discount
                                          </small>
                                       </h4>
                                       <small className="mb-0">
                                          The cost for an individual department is {/* {costPerDepartment * 12} for a year. */}
                                          {subscriptionDetails?.costPerDepartment * 12} for a year.
                                       </small>
                                    </div>
                                    <div className="col-md-3 pe-0">
                                       <h3 className="h4 text-right">
                                          ₹
                                          {subscriptionDetails?.annual_plan?.discountedYearlyCost
                                             ? subscriptionDetails?.annual_plan?.discountedYearlyCost
                                             : 0}
                                       </h3>
                                       <h4 className="h6 text-center">
                                          <del>
                                             ₹
                                             {subscriptionDetails?.annual_plan?.totalYearlyCost
                                                ? subscriptionDetails?.annual_plan?.totalYearlyCost
                                                : 0}
                                          </del>
                                       </h4>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="col-12 text-center">
                              {/* Button trigger modal */}
                              <button
                                 type="button"
                                 className="btn sign-up-button-color text-white px-5 mt-1 w-100 max-width-277 sign-up-button-color-onempty"
                                 // data-bs-toggle="modal"
                                 // data-bs-target="#paymentSuccess"
                                 style={{ backgroundColor: amount ? "#6993ff" : "#acacac" }}
                                 onClick={showRazorpay}
                                 disabled={!amount}
                              >
                                 Proceed to Pay
                              </button>
                              <button
                                 type="button"
                                 className="btn btn sign-up-button-color text-white px-5 mt-3"
                                 id="shareInvite"
                                 data-bs-toggle="modal"
                                 data-bs-target="#staticBackdrop"
                              >
                                 Share Invite #
                              </button>
                           </div>

                           {/*tell us about Modal */}
                           <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabIndex={-1}
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                           >
                              <div className="modal-dialog modal-dialog-centered">
                                 <div className="modal-content border-0 p-4">
                                    <div className="modal-header py-0 border-0">
                                       <h2 className="textBlue fw-normal">Tell us about your company</h2>
                                       <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() => formik.resetForm()}
                                       />
                                    </div>
                                    <div className="modal-body text-start">
                                       <form>
                                          <div className="mb-4">
                                             <div className="form-group">
                                                <label htmlFor="phoneno">
                                                   <span className="mandatory-star me-1">*</span>
                                                   About your Company
                                                </label>
                                                <input
                                                   type="text"
                                                   className="form-control mt-2"
                                                   placeholder="Describe your company...."
                                                   id="exampleInputfirstname"
                                                   name="about_us"
                                                   value={formik.values.about_us}
                                                   onChange={formik.handleChange}
                                                   maxLength={1000}
                                                />
                                             </div>
                                             {formik.errors.about_us && formik.touched.about_us ? (
                                                <div className="text-danger">{formik.errors.about_us}</div>
                                             ) : null}
                                          </div>
                                          <div className="row">
                                             <div className="col-6">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <label htmlFor="phoneno">
                                                         <span className="mandatory-star me-1">*</span>
                                                         Company Mission
                                                      </label>
                                                      <textarea
                                                         className="form-control"
                                                         placeholder="Mention your Company Mission..."
                                                         id="floatingTextarea"
                                                         rows="2"
                                                         name="mission"
                                                         value={formik.values.mission}
                                                         onChange={formik.handleChange}
                                                         maxLength={500}
                                                      ></textarea>
                                                   </div>
                                                   {formik.errors.mission && formik.touched.mission ? (
                                                      <div className="text-danger">{formik.errors.mission}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                             <div className="col-6">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <label htmlFor="phoneno">
                                                         <span className="mandatory-star me-1">*</span>
                                                         Company Vision
                                                      </label>
                                                      <textarea
                                                         className="form-control"
                                                         placeholder="Mention your Company Vision..."
                                                         id="floatingTextarea"
                                                         rows="2"
                                                         name="vision"
                                                         value={formik.values.vision}
                                                         onChange={formik.handleChange}
                                                         maxLength={500}
                                                      ></textarea>
                                                   </div>
                                                   {formik.errors.vision && formik.touched.vision ? (
                                                      <div className="text-danger">{formik.errors.vision}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row">
                                             <label htmlFor="phoneno">
                                                <span className="mandatory-star me-1">*</span>
                                                Mention the 3 differentiator of your company ?
                                             </label>
                                             <div className="col-4">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <input
                                                         type="text"
                                                         className="form-control mt-2"
                                                         placeholder="differentiator"
                                                         id="exampleInputfirstname"
                                                         name="firstKeyFactor"
                                                         value={formik.values.firstKeyFactor}
                                                         onChange={formik.handleChange}
                                                         maxLength={500}
                                                      />
                                                   </div>
                                                   {formik.errors.firstKeyFactor && formik.touched.firstKeyFactor ? (
                                                      <div className="text-danger">{formik.errors.firstKeyFactor}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                             <div className="col-4">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <input
                                                         type="text"
                                                         className="form-control mt-2"
                                                         placeholder="differentiator"
                                                         id="exampleInputfirstname"
                                                         name="secondKeyFactor"
                                                         value={formik.values.secondKeyFactor}
                                                         onChange={formik.handleChange}
                                                         maxLength={500}
                                                      />
                                                   </div>
                                                   {formik.errors.secondKeyFactor && formik.touched.secondKeyFactor ? (
                                                      <div className="text-danger">{formik.errors.secondKeyFactor}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                             <div className="col-4">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <input
                                                         type="text"
                                                         className="form-control mt-2"
                                                         placeholder="differentiator"
                                                         id="exampleInputfirstname"
                                                         value={formik.values.thirdKeyFactor}
                                                         name="thirdKeyFactor"
                                                         onChange={formik.handleChange}
                                                         maxLength={500}
                                                      />
                                                   </div>
                                                   {formik.errors.thirdKeyFactor && formik.touched.thirdKeyFactor ? (
                                                      <div className="text-danger">{formik.errors.thirdKeyFactor}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="mb-4">
                                             <div className="form-group">
                                                <label htmlFor="phoneno">
                                                   <span className="mandatory-star me-1">*</span>
                                                   Tell us your purpose with OKR ?
                                                </label>
                                                <textarea
                                                   className="form-control"
                                                   placeholder="Describe your purpose with OKR..."
                                                   id="floatingTextarea"
                                                   rows="2"
                                                   value={formik.values.purpose}
                                                   name="purpose"
                                                   onChange={formik.handleChange}
                                                   maxLength={500}
                                                ></textarea>
                                             </div>
                                             {formik.errors.purpose && formik.touched.purpose ? (
                                                <div className="text-danger">{formik.errors.purpose}</div>
                                             ) : null}
                                          </div>
                                          <div className="mb-4">
                                             <div className="form-group">
                                                <label htmlFor="phoneno">
                                                   <span className="mandatory-star me-1">*</span>
                                                   What solution are you providing ?
                                                </label>
                                                <textarea
                                                   className="form-control"
                                                   placeholder="Describe your company...."
                                                   id="floatingTextarea"
                                                   rows="2"
                                                   value={formik.values.solution}
                                                   name="solution"
                                                   onChange={formik.handleChange}
                                                   maxLength={500}
                                                ></textarea>
                                             </div>
                                             {formik.errors.solution && formik.touched.solution ? (
                                                <div className="text-danger">{formik.errors.solution}</div>
                                             ) : null}
                                          </div>
                                       </form>
                                    </div>
                                    <div className="modal-footer border-0">
                                       {/* <NavLink to="/dashboard"> */}
                                       <button
                                          type="submit"
                                          className="btn sign-up-button-color text-white px-5"
                                          onClick={formik.handleSubmit}
                                       >
                                          Submit
                                       </button>
                                       {/* </NavLink> */}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/*tell us about Modal */}
                        </div>
                     </div>
                  </div>
               </div>
               {/* need tobe changed  end*/}
            </div>
         ) : (
            <div className="row justify-content-center mt-4">
               <div className="col-xxl-12 col-12">
                  <h2 className="textBlue fw-normal">Share Invite</h2>
                  <p className="textGrey">Share the link with the below listed departments</p>
                  <div className="row m-0 gap-5">
                     <div className="col-xl-6 mb-1 flex-column d-flex  emailoOuterContainer ps-0 pe-0 scroll-max-height custom-scrollnar">
                        {departmentListLocallyPublish &&
                           departmentListLocallyPublish?.map((item, index) => {
                              return (
                                 <>
                                    <div className="row m-0 row-cols-2 p-2" key={index}>
                                       <div className="col-10 ">
                                          <div className="setObjectiveListBox row p-3 d-flex">
                                             <div className="maxWidth30 ps-0" style={{ maxWidth: "30px" }}>
                                                <img src={departmentIcon} className="" alt="department icon" />
                                             </div>
                                             <div className="setObjectiveListBox pe-0" style={{ maxWidth: "calc(100% - 30px)" }}>
                                                <p className="mb-1 fw-medium" style={{ overflowWrap: "break-word" }}>
                                                   {item?.dept_name}
                                                </p>
                                                <p
                                                   className="textBlueLight1 mb-0 fw-medium"
                                                   style={{ overflowWrap: "break-word" }}
                                                >
                                                   {item?.email}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                       {/* <div className="col-2 d-flex ps-2">
                            <div className="d-flex align-middle flex-column flex-md-row flex-lg-row flex-xl-row flex-xxl-row">
                              <img
                                src={editIcon}
                                onClick={() => handleEdit(item.dept_name)}
                                className="cursor-pointer"
                                alt="edit icon"
                              />
                            </div>
                          </div> */}
                                    </div>
                                 </>
                              );
                           })}
                     </div>
                     <div className="col  mx-n3 share-invite-right ps-0 pe-0">
                        <p className="textGrey fw-regular mb-2">Total Department Added</p>
                        <h3 className="textGrey fw-normal h3 mb-3">{departmentListLocallyPublish.length}</h3>
                        <p className="mb-2 d-block textBlueLight1">
                           Congratulations! You've activated the{" "}
                           {paymentSuccessfulRes?.subscription_data?.plan_type.charAt(0).toUpperCase() +
                              paymentSuccessfulRes?.subscription_data?.plan_type.slice(1)}{" "}
                           plan:
                        </p>

                        <div className="list-group" id="list-tab" role="tablist">
                           <div class="price-tabs-final mb-3">
                              <div className="row m-0">
                                 <div className="col-md-9 p-0">
                                    <h4 className="h5 textBlueLight1 fw-normal">
                                       {" "}
                                       {paymentSuccessfulRes?.subscription_data?.plan_type.charAt(0).toUpperCase() +
                                          paymentSuccessfulRes?.subscription_data?.plan_type.slice(1)}{" "}
                                       Plan{" "}
                                       <small className="small p fs-6">
                                          ({paymentSuccessfulRes?.subscription_data?.plan_type === "annual" ? "365" : "90"} days)
                                       </small>
                                    </h4>
                                    <small className="textBlueLight1 mb-0">
                                       {/* The cost for an individual department is ₹1200 for a
                          year. */}
                                       {paymentSuccessfulRes?.subscription_data?.planType === "annual"
                                          ? "The cost for an individual department is ₹3000 for a year."
                                          : "The cost for an individual department is ₹250 per month."}
                                    </small>
                                 </div>
                                 <div className="col-md-3 pe-0">
                                    <h3 className="textBlueLight1 h4 text-right">₹{paymentSuccessfulRes?.data?.paid_amount}</h3>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <p className="mb-2 d-block textDarkGrey">
                           Download your payment invoice:{" "}
                           <button
                              className="textBlueLight1"
                              style={{
                                 background: "none",
                                 border: "none",
                                 padding: 0,
                                 color: "blue",
                                 // textDecoration: "underline",
                                 // cursor: "pointer",

                                 // color: loading1 ? "gray" : "blue", // Change color based on loading1 (disabled state)
                                 textDecoration: loading1 ? "none" : "underline", // Remove underline when disabled if needed
                                 cursor: loading1 ? "not-allowed" : "pointer", // Show appropriate cursor
                              }}
                              onClick={(event) => generatePaymentInvoice(event)}
                              disabled={loading1}
                           >
                              {loading1 ? "Downloading..." : "Download PDF"}
                           </button>
                        </p>

                        <div className="col-12 text-center">
                           <button
                              type="button"
                              className="btn btn sign-up-button-color text-white px-5 mt-3 w-100 max-width-277"
                              onClick={(e) => shareInviteOnEmail(e)}
                           >
                              {loading ? "Please wait.." : " Next"}
                           </button>
                           <button
                              type="button"
                              className="btn btn sign-up-button-color text-white px-5 mt-3"
                              id="shareInvite"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                           >
                              Share Invite #
                           </button>
                           <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabIndex={-1}
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                           >
                              <div className="modal-dialog modal-dialog-centered">
                                 <div className="modal-content border-0 p-4">
                                    <div className="modal-header py-0 border-0">
                                       <h2 className="textBlue fw-normal">Tell us about your company</h2>
                                       <button
                                          type="button"
                                          className="btn-close"
                                          style={{
                                             display: validCoupon ? "none" : "block",
                                          }}
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() => formik.resetForm()}
                                       />
                                    </div>
                                    <div className="modal-body text-start">
                                       <form>
                                          <div className="mb-4">
                                             <div className="form-group">
                                                <label htmlFor="phoneno">
                                                   <span className="mandatory-star me-1">*</span>
                                                   About your Company
                                                </label>
                                                <input
                                                   type="text"
                                                   className="form-control mt-2"
                                                   placeholder="Describe your company...."
                                                   id="exampleInputfirstname"
                                                   name="about_us"
                                                   value={formik.values.about_us}
                                                   onChange={formik.handleChange}
                                                />
                                             </div>
                                             {formik.errors.about_us && formik.touched.about_us ? (
                                                <div className="text-danger">{formik.errors.about_us}</div>
                                             ) : null}
                                          </div>
                                          <div className="row">
                                             <div className="col-6">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <label htmlFor="phoneno">
                                                         <span className="mandatory-star me-1">*</span>
                                                         Company Mission
                                                      </label>
                                                      <textarea
                                                         className="form-control"
                                                         placeholder="Mention your Company Mission..."
                                                         id="floatingTextarea"
                                                         rows="2"
                                                         name="mission"
                                                         value={formik.values.mission}
                                                         onChange={formik.handleChange}
                                                      ></textarea>
                                                   </div>
                                                   {formik.errors.mission && formik.touched.mission ? (
                                                      <div className="text-danger">{formik.errors.mission}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                             <div className="col-6">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <label htmlFor="phoneno">
                                                         <span className="mandatory-star me-1">*</span>
                                                         Company Vision
                                                      </label>
                                                      <textarea
                                                         className="form-control"
                                                         placeholder="Mention your Company Vision..."
                                                         id="floatingTextarea"
                                                         rows="2"
                                                         name="vision"
                                                         value={formik.values.vision}
                                                         onChange={formik.handleChange}
                                                      ></textarea>
                                                   </div>
                                                   {formik.errors.vision && formik.touched.vision ? (
                                                      <div className="text-danger">{formik.errors.vision}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row">
                                             <label htmlFor="phoneno">
                                                <span className="mandatory-star me-1">*</span>
                                                Mention the 3 differentiator of your company ?
                                             </label>
                                             <div className="col-4">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <input
                                                         type="text"
                                                         className="form-control mt-2"
                                                         placeholder="differentiator"
                                                         id="exampleInputfirstname"
                                                         name="firstKeyFactor"
                                                         value={formik.values.firstKeyFactor}
                                                         onChange={formik.handleChange}
                                                      />
                                                   </div>
                                                   {formik.errors.firstKeyFactor && formik.touched.firstKeyFactor ? (
                                                      <div className="text-danger">{formik.errors.firstKeyFactor}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                             <div className="col-4">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <input
                                                         type="text"
                                                         className="form-control mt-2"
                                                         placeholder="differentiator"
                                                         id="exampleInputfirstname"
                                                         name="secondKeyFactor"
                                                         value={formik.values.secondKeyFactor}
                                                         onChange={formik.handleChange}
                                                      />
                                                   </div>
                                                   {formik.errors.secondKeyFactor && formik.touched.secondKeyFactor ? (
                                                      <div className="text-danger">{formik.errors.secondKeyFactor}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                             <div className="col-4">
                                                <div className="mb-4">
                                                   <div className="form-group">
                                                      <input
                                                         type="text"
                                                         className="form-control mt-2"
                                                         placeholder="differentiator"
                                                         id="exampleInputfirstname"
                                                         value={formik.values.thirdKeyFactor}
                                                         name="thirdKeyFactor"
                                                         onChange={formik.handleChange}
                                                      />
                                                   </div>
                                                   {formik.errors.thirdKeyFactor && formik.touched.thirdKeyFactor ? (
                                                      <div className="text-danger">{formik.errors.thirdKeyFactor}</div>
                                                   ) : null}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="mb-4">
                                             <div className="form-group">
                                                <label htmlFor="phoneno">
                                                   <span className="mandatory-star me-1">*</span>
                                                   Tell us your purpose with OKR ?
                                                </label>
                                                <textarea
                                                   className="form-control"
                                                   placeholder="Describe your purpose with OKR..."
                                                   id="floatingTextarea"
                                                   rows="2"
                                                   value={formik.values.purpose}
                                                   name="purpose"
                                                   onChange={formik.handleChange}
                                                ></textarea>
                                             </div>
                                             {formik.errors.purpose && formik.touched.purpose ? (
                                                <div className="text-danger">{formik.errors.purpose}</div>
                                             ) : null}
                                          </div>
                                          <div className="mb-4">
                                             <div className="form-group">
                                                <label htmlFor="phoneno">
                                                   <span className="mandatory-star me-1">*</span>
                                                   What solution are you providing ?
                                                </label>
                                                <textarea
                                                   className="form-control"
                                                   placeholder="Describe your company...."
                                                   id="floatingTextarea"
                                                   rows="2"
                                                   value={formik.values.solution}
                                                   name="solution"
                                                   onChange={formik.handleChange}
                                                ></textarea>
                                             </div>
                                             {formik.errors.solution && formik.touched.solution ? (
                                                <div className="text-danger">{formik.errors.solution}</div>
                                             ) : null}
                                          </div>
                                       </form>
                                    </div>
                                    <div className="modal-footer border-0">
                                       <button
                                          type="submit"
                                          className="btn sign-up-button-color text-white px-5"
                                          onClick={formik.handleSubmit}
                                       >
                                          Submit
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}

         {/* <div
        className="modal fade"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="paymentSuccess"
        aria-hidden="true"
      >
        <div className="modal-dialog background-unset width-unset d-flex  justify-content-center modal-dialog-centered">
          <div className="modal-content border-0 p-4 align-self-center d-flex">
            <div className="modal-header py-0 border-0 pt-2">

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props?.setPaymentSuccessModal(false)
                }}
              />
            </div>
            <div className="modal-body text-center pt-0">
              <img src={successIcon} alt="sucess" width="40" height="40" className="mx-auto" />
              <p className="textDarkBlack flex-100 p mt-3 poppinsSemibold">
                Payment Successful
              </p>
              <p className="textDarkBlack">Your transaction has been successfully processed.</p>
              <button
                type="button"
                className="btn btn sign-up-button-color text-white px-5 mt-1 w-100 max-width-277"
                data-bs-dismiss="modal"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div> */}
         {afterPayment && (
            <>
               <div className="backdrop">
                  <div
                     className="registration-form-container "
                     style={{
                        width: "40%",
                        height: "40%",
                        position: "absolute",
                        top: "30%",
                        left: "30%",
                     }}
                  >
                     <div className="text-center mb-5">
                        <img src={successIcon} alt="sucess" width="50" height="50" className="mx-auto" />
                     </div>
                     <div className="text-center px-4 mt-2">
                        <h4 className="textDarkBlack flex-100 p mt-3 poppinsSemibold">{validCoupon ? "Coupon Applied Successfully" : "Payment Successful"}</h4>
                        <h4 className="textDarkBlack">Your transaction has been successfully processed.</h4>
                     </div>
                     <div className="d-flex justify-content-center">
                        {
                           !validCoupon && (

                              <button
                                 type="button"
                                 className="btn btn sign-up-button-color text-white px-5 mt-1 w-100 max-width-277"
                                 data-bs-dismiss="modal"
                                 onClick={() => {
                                    setAfterPayment(false);
                                 }}
                              >
                                 Okay
                              </button>
                           )
                        }
                     </div>
                  </div>
               </div>
            </>
         )}

         {/* {paymentSuccessModal && (
        <PaymentSuccessModal setPaymentSuccessModal={setPaymentSuccessModal} />
      )} */}

         <div className="row"></div>

         {/* delete modal
          */}
         <div
            className="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            // tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
         >
            <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
               <div className="modal-content border-0 p-5">
                  <div className="modal-body text-start text-center">
                     <p className="textGrey textDarkBlack poppinsSemibold">Are you sure you want to delete this?</p>
                     <div className="d-flex justify-content-center mt-3">
                        <button
                           className="btn btnOrganization w-auto px-5 mt-3 me-3 max-width-277 w-100"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        >
                           Cancel
                        </button>
                        <button
                           className="btn sign-up-button-color text-white px-5 mt-3 max-width-277 w-100"
                           onClick={() => deleteDepartment()}
                        >
                           Delete
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ShareAccess;
